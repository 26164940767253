<template>
  <div class="flex h-screen flex-col text-base">
    <header
      class="h-header-global lg:h-header-global-lg sticky top-0 z-40 bg-white"
    >
      <div class="container flex h-full items-center gap-4">
        <div class="flex flex-grow items-center">
          <GlobalLogo
            class="w-[7.75rem] translate-y-[2px] transform lg:w-[8.5rem]"
          />
        </div>
      </div>
    </header>
    <main class="bg-primary-500 flex flex-grow text-white">
      <slot />
    </main>
  </div>
</template>
